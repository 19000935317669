body {
  background-image: url("./images/background.jpg");
}

.App {
  text-align: center;
}

.top-logo {
  height: 45px;
  pointer-events: none;
  margin: 5px;
}

.app-logo-col {
  padding: 0 !important;
}

.App-logo {
  height: 45px;

  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 10s linear;
  }
}

.App-overlay {
  /* background-color: #282c34; */
  background: rgba(0, 0, 0, 0.9);
  vertical-align: top;
  min-height: 100vh;
  /* border: 5px solid #900000; */
  /* display: flex;
  flex-direction: column; */
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.innerMain {
  padding-top: 15vh;
}

.infoBtn {
  border: none;
  background: none;
  color: #f9d710;
  font-size: medium;
  outline: none;
  cursor: pointer;
}

.infoBox {
  color: #dddddd;
  margin-top: 3vh;
  font-size: medium;
}

.infoBox ul {
  /* justify-content: center; */
  /* display: flex; */
  display: inline-table;
}

.infoBox li {
  text-align: left;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
